import React, { useEffect, useState } from 'react';
import { Modal } from "antd";
import Error from "../PublicViews/Error";
import InputMask from "react-input-mask";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import Auth from "../../models/Auth";

export const ModalConfirmarModificacionPlazo = ({
    openConfirmarModificacion,
    cancelConfirmarModificacion,
    fiscalNumber,
    logModification

}) => {
    const [otpCode, setOtpCode] = useState('');
    const [loading, setLoading] = useState(false)
    const [isValidCode, setIsValidCode] = useState(false);
    const [openOtpModal, setOpenOtpModal] = useState(false);
    const [openConfirmModal, setopenConfirmModal] = useState(false)
    const [openConfirmarGuardarCambio, setOpenConfirmarGuardarCambio] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setopenConfirmModal(openConfirmModal)
        setOpenOtpModal(false)
    }, [openConfirmarModificacion])


    const confirmarCambioPlazo = event => {
        event.preventDefault();
        setOpenOtpModal(false)
        setOtpCode('')
        setOpenConfirmarGuardarCambio(false)
        cancelConfirmarModificacion(true)
    }
    const cancelarCambioPlazo = event => {
        event.preventDefault();
        setOpenConfirmarGuardarCambio(false)
        setOpenOtpModal(false)
        cancelConfirmarModificacion()
    }

    const confirmarModificacionEnviarOtp = (e) => {
        e.preventDefault();

        setLoading(true)
        const data = {
            fiscal_number: fiscalNumber,
            all_data: false,
            log_modification:logModification
        }
        fetch(settings.backend.sendOtpModifyTerms, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: settings.headers_super_auth,
        })
            .then(FetchResponses.processResponse)
            .then((res) => {
                setLoading(false)
                if(res.detail){
                    setOpenError(true)
                    setErrorMessage(res.detail)
                }else if(res.estado && res.estado === 'ERROR'){
                    
                    setOpenError(true)
                    setErrorMessage(res.mensaje)   
                }else{
                    setopenConfirmModal(false)
                    setOpenOtpModal(true)
                }
            })
            .catch((err) => {

                setLoading(false)
                setOpenError(true)
                setErrorMessage('Error en el envio del código Otp')
            })
        // Add your OTP confirmation logic here
    };
    const confirmarCodigoOtp = event => {
        event.preventDefault()

        setLoading(true)
        const data = {
            "documento": fiscalNumber,
            "codigo": otpCode
        }
        fetch(settings.backend.validate_otp, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: settings.headers_super_auth,
        })
            .then(FetchResponses.processResponse)
            .then((res) => {
                setLoading(false)
                if (res.estado.toUpperCase() === 'ERROR') {
                    setErrorMessage(res.mensaje)
                    setOpenError(true)
                    setOtpCode('')
                } else {
                    setOpenOtpModal(false)
                    setOpenConfirmarGuardarCambio(true)
                    enviarNotificacionConfirmacionOtp()
                }

            })
            .catch((err) => {
                setLoading(false)
                setErrorMessage('Error en el servicio e verificación del código OTP')
                setOpenError(true)
                setOtpCode('')
            })
    }


    const enviarNotificacionConfirmacionOtp = () => {
        const url = `${settings.backend.sendNotificationModifyTerms}${fiscalNumber}/2`;
        fetch(url, {
            method: "GET",
            headers: settings.headers_auth(Auth.getSessionToken()) || settings.headers_super_auth,
        })
            .then(FetchResponses.processResponse)
            .then((data) => {
                console.log("Notification sent successfully:", data);
            })
            .catch((error) => {
                console.error("Error sending notification:", error);
            });
    };

    const handleChangeOtpCode = (e) => {
        let value = e.target.value.replace(/\D|-/, '');
        // Ensure only up to 6 digits are allowed
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
        setOtpCode(value); // Update the state with the new OTP value
        setIsValidCode(value.length === 6); // Enable the button if the length is 6
    };

    const closeModal = () => {
        setOtpCode('')
        cancelConfirmarModificacion()
    }

    const handleCloseError = () =>{
        if(errorMessage === 'Intentos de validación superados' || errorMessage === 'El cliente no tiene un código OTP activo'){
            setOpenOtpModal(false)
            cancelConfirmarModificacion()
        }
        setOpenError(false)
    }


    return (
        <>

            <Modal
                visible={openConfirmarModificacion}
                onCancel={closeModal}
                closable={false}
                maskClosable={false}
                footer={
                    loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>

                    ) : (
                        [
                            <button
                                key="cancel"
                                className="btn btn-secondary"
                                onClick={()=>cancelConfirmarModificacion()}
                            >
                                Cancelar
                            </button>,
                            <button
                                key="confirm"
                                className="btn btn-primary"
                                type="button"
                                onClick={confirmarModificacionEnviarOtp}
                            >
                                Confirmar
                            </button>,
                        ]
                    )
                }
            >
                <div className="ant-confirm-body-wrapper">
                    <div className="ant-confirm-body">
                        <i className="anticon anticon-info-circle" />
                        <span className="ant-confirm-title">Alkomprar te informa.</span>
                        <div className="ant-confirm-content">
                            <p className='mt-3 mb-2 '>
                                Estás a punto de realizar un cambio en las condiciones de tu crédito. Al proceder, estarás aceptando los términos, condiciones y la tasa vigente que se aplicará con este cambio, los cuales están detallados en https://www.alkomprar.com/credito-20-minutos/legal. Si estás de acuerdo, selecciona la opción 'CONFIRMAR'. Si prefieres cancelar el proceso, selecciona 'CANCELAR'.
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                visible={openConfirmarGuardarCambio}
                onCancel={closeModal}
                closable={false}
                maskClosable={false}
                footer={
                    loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>

                    ) : (
                        [
                            <button
                                key="cancel"
                                className="btn btn-secondary"
                                onClick={cancelarCambioPlazo}
                            >
                                Cancelar
                            </button>,
                            <button
                                key="confirm"
                                className="btn btn-primary"
                                type="button"
                                onClick={confirmarCambioPlazo}
                            >
                                Confirmar
                            </button>,
                        ]
                    )
                }
            >
                <div className="ant-confirm-body-wrapper">
                    <div className="ant-confirm-body">
                        <i className="anticon anticon-info-circle" />
                        <span className="ant-confirm-title">Alkomprar te informa.</span>
                        <div className="ant-confirm-content">
                            <p className='mt-3 mb-2 '> 
                                Con el ingreso del código OTP estas autorizando la modificación del plazo de tu crédito en caso 
                                querer continuar selecciona el botón CONFIRMAR , de lo contario selecciona la opción de CANCELAR .
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                visible={openOtpModal}
                onCancel={closeModal}
                closable={false}
                maskClosable={false}
                footer={
                    loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>

                    ) : ([
                        <button className='btn btn-secondary' onClick={()=>cancelConfirmarModificacion()}>
                            Cancelar
                        </button>,
                        <button className='btn btn-primary' type="button" onClick={confirmarCodigoOtp} disabled={!isValidCode}>
                            Confirmar
                        </button>,
                    ])}
            >
                <div className="ant-confirm-body-wrapper">
                    <div className="ant-confirm-body">
                        <i className="anticon anticon-info-circle" />
                        <span className="ant-confirm-title">Alkomprar te informa.</span>
                        <div className="ant-confirm-content">

                            <p className='mt-3 mb-2 '>
                                Con el fin de continuar con el proceso ingresa el código OTP que fue enviado a tu numero de celular y/o 
                                correos registrados , selecciona la opción CONFIRMAR con el cual confirmaras la modificación de tu 
                                crédito y aceptación de condiciones, en caso de no querer continuar selecciona la opción CANCELAR
                            </p>
                            <form onSubmit={confirmarCodigoOtp} className="whitePanel">

                                <div className="form-group">
                                    <InputMask
                                        className="form-control"
                                        type="text"
                                        placeholder="Ingresa aquí tu código de confirmación"
                                        value={otpCode}
                                        required
                                        maxLength={6}
                                        minLength={6}
                                        onChange={handleChangeOtpCode}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>

            <Error
                open={openError}
                error_message={errorMessage}
                handleClose={handleCloseError}
            />
        </>

    );
};
