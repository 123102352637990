import React, { Component } from "react";
import Logger from "../../models/Logger";

import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import Error from "../PublicViews/Error";
import TagManager from 'react-gtm-module'
import { push } from "react-router-redux";

class PayInvoice extends Component {
  constructor(props) {
    super(props);
    let quotas = [...this.props.invoice.quotas].sort();
    let total_pay = this.props.invoice.mora;
    let invoice = this.props.invoice.invoice;

    this.state = {
      invoice,
      quotas,
      total_pay,
      max: total_pay,
      total: 0,
      text_collapse: "Ver más",
      checked_all: false,
      inputDisable: false,
      showTotal: true,
      open: false,
      error_message: ''

    };
    Logger.setLogger(this.constructor.name);
  }

  handleChange = (event) => {

    this.props.addTotalDiscount(this.state.total_pay - this.props.invoice.mora);
    let quota_id = event.target.id;
    let quotas = [];
    let total = 0;
    let infoQuota = {}

    if (quota_id === "checked_all") {
      if (event.target.checked) {
        quotas = [...this.state.quotas].map((quota) => {
          quota.selected = true;
          this.setState({ inputDisable: true });
          return quota;
        });
      } else {

        quotas = [...this.state.quotas].map((quota) => {
          quota.selected = false;
          this.setState({ inputDisable: false });
          return quota;
        });
      }
    } else {
      if (event.target.checked) {
        quotas = [...this.state.quotas].map((quota) => {
          if (quota.num_quota <= quota_id) {
            infoQuota = {
              quantity: quota.num_quota,
              dimension1: quota.num_quota,
              dimension2: quota.exp_date,
              dimension3: quota.date_mora
            }
            quota.selected = true;
          }
          return quota;
        });
      } else {
        quotas = [...this.state.quotas].map((quota) => {
          if (quota.num_quota >= quota_id) {
            quota.selected = false;
          }

          if (quota.num_quota == (quota_id - 1)) {
            infoQuota = {
              quantity: quota.num_quota,
              dimension1: quota.num_quota,
              dimension2: quota.exp_date,
              dimension3: quota.date_mora
            }
          }
          return quota;
        });
      }
    }

    quotas.forEach((quota) => {
      if (quota.selected) {
        total += quota.quota_value;
      }
    });

    infoQuota['price'] = this.props.invoice.mora + total;
    this.sendDataLayerAddToCart(infoQuota)

    this.setState({
      quotas,
      total,
      total_pay: this.props.invoice.mora + total,
      max: this.props.invoice.mora + total,
    });
    this.props.addTotalQuotas(total);
    this.props.manageInvoice(this.state.invoice, total, infoQuota);
  };

  handleTotalPay = (event) => {

    let total_pay = event.target.value;
    this.props.manageInvoice(
      this.state.invoice,
      total_pay - this.props.invoice.mora
    );
    if (total_pay) {
      this.props.addTotalDiscount(
        this.state.total_pay - this.props.invoice.mora
      );
      this.setState({
        [event.target.id]: parseInt(event.target.value),
      });
      this.props.addTotalQuotas(total_pay - this.props.invoice.mora);
    }
  };

  changeTextCollapse = (event) => {

    let text_collapse = "Ver más";
    if (event.target.parentElement.className !== "collapsed") {
      text_collapse = "Ver menos";
      this.sendDataLayerProductClic()
      this.sendDataLayerProductDetail()
    }
    this.setState({ text_collapse });
  };

  handleCheckTotal = (event) => {


    let total_payment = event.target.checked;
    if (event.target.checked) {

      //Initialize again the counter of the total and uncheck the quotes.
      //it must be subtracted the same amount of the total to the counter of the PSE total

      // this.props.addTotalQuotas(this.props.invoice.mora-this.state.total_pay);
      // this.props.manageInvoice(this.state.invoice, 0);
      // this.props.addTotalPaymentFlag(event.target.checked,this.state.invoice)

      let totalValueQuotas = this.props.invoice.mora - (this.state.total_pay);
      let amountWithoutMora = 0;
      this.handlePropsFunctions(totalValueQuotas, this.state.invoice, amountWithoutMora, total_payment)

      let quotas = [...this.state.quotas].map((quota) => {
        quota.selected = false;
        return quota;
      });
      this.setState({
        showTotal: false,
        total_pay: this.props.invoice.mora,
        quotas
      });


      let url = `${settings.backend.totalToPay}/${this.props.custId}/${this.props.invoice.invoice}`;
      fetch(url, {
        method: "GET",
        headers: settings.headers_super_auth,
      })
        .then(FetchResponses.processResponse)
        .then((response) => {
          this.setState({
            total_pay: response.saldoTotal,
            max: this.props.invoice.mora + response.saldoTotal,
            showTotal: true
          });
          //it must be added the amount of the total to the counter of the PSE total
          // this.props.addTotalPaymentFlag(total_payment,this.state.invoice)
          // this.props.addTotalQuotas(response.saldoTotal);
          // this.props.manageInvoice(this.state.invoice, response.saldoTotal);

          this.sendDataLayerAddToCartTotal(response.saldoTotal)
          let totalValueQuotas = response.saldoTotal - response.interesDeMora;
          let amountWithoutMora = response.saldoTotal - response.interesDeMora ;
          this.handlePropsFunctions(totalValueQuotas, this.state.invoice, amountWithoutMora, total_payment)

        }).catch(this.failureDataResponse);
    } else {
      //it must be subtracted the same amount of the total to the counter of the PSE total

      // this.props.addTotalQuotas(this.props.invoice.mora - this.state.total_pay);
      // this.props.manageInvoice(this.state.invoice, 0);
      // this.props.addTotalPaymentFlag(total_payment,this.state.invoice)
      let totalValueQuotas = this.props.invoice.mora - this.state.total_pay;
      let amountWithoutMora = 0;
      this.handlePropsFunctions(totalValueQuotas, this.state.invoice, amountWithoutMora, total_payment)
      this.setState({
        total_pay: this.props.invoice.mora,
        showTotal: true
      });
    }
    this.setState({ checked_all: !this.state.checked_all });
  };

  handlePropsFunctions = (totalValueQuotas, invoice, amountWithoutMora, totalPaymentFlag) => {
    let infoQuota = { price: (totalValueQuotas + this.props.invoice.mora) }
    this.props.addTotalQuotas(totalValueQuotas);
    this.props.manageInvoice(invoice, amountWithoutMora, infoQuota);
    this.props.addTotalPaymentFlag(totalPaymentFlag, invoice)
  }

  failureDataResponse = response => {
    this.setState({
      open: true,
      error_message: 'Ups! algo salió mal, Por favor comuniquese con admin',
      showTotal: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  sendDataLayerProductClic = () => {
    let price = 0;
    this.props.invoice.quotas.forEach(({ quota_value }) => price += quota_value)

    let tagManagerArgs = {
      event: 'productClick',
      ecommerce: {
        click: {
          products: [{
            name: 'Factura' + this.props.invoice.invoice,
            id: this.props.invoice.invoice,
            price,
            brand: 'Credito 20 Minutos',
            category: 'Pagar Facturas/Facturas',
            list: 'Pagar Facturas',
            dimension1: this.props.invoice.quotas[0].num_quota,
            dimension2: this.props.invoice.quotas[0].exp_date,
            dimension3: this.props.invoice.quotas[0].date_mora
          }]
        }
      }
    }
    TagManager.dataLayer({
      dataLayer: tagManagerArgs
    })

  }

  sendDataLayerProductDetail = () => {
    let price = 0;
    this.props.invoice.quotas.forEach(({ quota_value }) => price += quota_value)

    let tagManagerArgs = {
      event: 'productDetail',
      ecommerce: {
        detail: {
          actionField: {
            list: 'Pagar Facturas',
            id: this.props.invoice.invoice,
          }
        },
        products: [{
          name: 'Factura' + this.props.invoice.invoice,
          id: this.props.invoice.invoice,
          price,
          brand: 'Credito 20 Minutos',
          category: 'Pagar Facturas/Facturas',
          dimension1: this.props.invoice.quotas[0].num_quota,
          dimension2: this.props.invoice.quotas[0].exp_date,
          dimension3: this.props.invoice.quotas[0].date_mora
        }]

      }
    }
    TagManager.dataLayer({
      dataLayer: tagManagerArgs
    })

  }

  sendDataLayerAddToCart = (infoQuota) => {
    let tagManagerArgs = {
      event: "addToCart",
      ecommerce: {
        currencyCode: "COP",
        add: {
          products: [
            {
              name: 'Factura' + this.props.invoice.invoice,
              id: this.props.invoice.invoice,
              brand: 'Credito 20 Minutos',
              category: 'Pagar Facturas/Facturas',
              ...infoQuota,
            }
          ]
        }
      }
    }

    TagManager.dataLayer({
      dataLayer: tagManagerArgs
    })
  }

  sendDataLayerAddToCartTotal = (priceTotal) => {

    let products = [];
    [...this.state.quotas].forEach((quota) => {
      let infoQuota = {
        name: 'Factura' + this.props.invoice.invoice,
        id: this.props.invoice.invoice,
        brand: 'Credito 20 Minutos',
        category: 'Pagar Facturas/Facturas',
        quantity: quota.num_quota,
        dimension1: quota.num_quota,
        dimension2: quota.exp_date,
        dimension3: quota.date_mora,
        price:priceTotal
      }
      products.push(infoQuota)
    });

    let tagManagerArgs = {
      event: "addToCart",
      ecommerce: {
        currencyCode: "COP",
        add: {
          products
        }
      }
    }
    TagManager.dataLayer({
      dataLayer: tagManagerArgs
    })
  }

  

  render() {
    return (
      <>
        <div className="panel">
          <div className="">
            <h2 className=" d-md-block">
              <u className="collapsed"
                data-bs-toggle="collapse"
                href={`#invoice${this.props.invoice.invoice}`}
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={this.changeTextCollapse}>
                Factura <strong>{this.props.invoice.invoice}</strong>
              </u>

                  <button
                    type="button"
                    className="btn btn-secondary ms-3"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => this.props.handleClikModificarPlazo(this.props.invoice.invoice)}
                    title="Puede realizar la modificación del plazo de su obligación.
                          En caso de haber realizado pago el día de hoy, 
                          este no se tendrá en cuenta en la modificación."
                  >
                    Modificar Plazo Cuota
                  </button>
            </h2>
          </div>
          <div className="invoices-content collapse"
            id={`invoice${this.props.invoice.invoice}`}
          >
             {!this.props.carteraCastigada && (
              <p className="text-end">
                Pago Total
                <input
                  style={{ marginLeft: "0.3em" }}
                  type="checkbox"
                  className="check-input"
                  id="checked_all"
                  checked={this.state.checked_all}
                  onChange={this.handleCheckTotal} />
              </p>
            )
            } 

            {!this.state.checked_all && (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center" scope="col">
                        Cuota
                      </th>
                      <th className="text-center d-none d-md-table-cell"
                        scope="col">
                        Fecha de <br /> vencimiento
                      </th>
                      <th className="text-center d-md-none" scope="col">
                        Fecha
                      </th>
                      <th className="text-center d-none d-md-table-cell"
                        scope="col">
                        Valor Cuota
                      </th>
                      <th className="text-center d-md-none" scope="col">
                        Valor
                      </th>
                      <th className="text-center d-none d-md-table-cell"
                        scope="col">
                        Días de <br />
                        Mora
                      </th>
                      <th className="text-center d-md-none" scope="col">
                        Días Mora
                      </th>
                      <th className="text-center d-none d-md-table-cell"
                        scope="col">
                        Seleccionar <br /> para Pago
                      </th>
                      <th className="text-center d-md-none" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.quotas.map((quota) => {
                      return (
                        <tr key={`${quota.num_quota}-${quota.invoice.toLocaleString(
                          "de-DE",
                          { maximumFractionDigits: 0 }
                        )}`}>
                          <td className="text-center">{quota.num_quota}</td>
                          <td className="text-center">{quota.exp_date}</td>
                          <td className="text-center">
                            ${" "}
                            {quota.quota_value.toLocaleString("de-DE", {
                              maximumFractionDigits: 0,
                            })}
                          </td>
                          <td className="text-center">{quota.date_mora}</td>
                          <td className="text-center">
                            {this.state.inputDisable ? (
                              <input
                                type="checkbox"
                                className="check-input"
                                id={quota.num_quota}
                                value={quota.quota_value}
                                checked={true}
                                disabled={true}
                                onChange={this.handleChange}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                className="check-input"
                                id={quota.num_quota}
                                value={quota.quota_value}
                                checked={quota.selected}
                                onChange={this.handleChange}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {this.state.showTotal ? (
              <div className="align-items-center alert alert-info table-mobile mb-2">
                <div className="row">
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-7 text-left">
                        <span>INTERESES DE MORA:</span>
                      </div>
                      <div className="col-5 text-left interest-mora">
                        <span className="padding-price">$</span>
                        <span className="padding-price">
                          {this.props.invoice.mora.toLocaleString("de-DE", {
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-7 text-left">
                        <strong>VALOR A PAGAR:</strong>
                      </div>
                      <div className="col-5 text-left">
                        <div className="input-group mt-3">
                          <span className="input-group-text">$</span>
                          <input
                            className="form-control"
                            type="number"
                            id="total_pay"
                            disabled={this.state.checked_all}
                            value={this.state.total_pay}
                            min={this.props.invoice.mora}
                            max={this.state.max}
                            onChange={this.handleTotalPay}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                }}
              >
                <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
              </div>
            )}
          </div>
        </div>

        <Error
          open={this.state.open}
          error_message={this.state.error_message}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}

export default PayInvoice;
