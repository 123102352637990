import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Auth from "../../models/Auth";
import { clsx } from 'clsx';
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import MenuList from './MenuList';
import HeaderPrivate from './HeaderPrivate';
import Error from "../PublicViews/Error";
import Footer from "../PublicViews/Footerpersonalizado";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import { setModificarPlazoCreditoData } from "../Actions//ModificarPlazo_Actions";
import { BarLoader } from 'react-spinners';
import { ModalConfirmarModificacionPlazo } from './ModalConfirmarModificacionPlazo';
import Pagination from 'react-js-pagination'

const ModificarPlazoCredito = ({ dataModificacion }) => {


    const history = useHistory();
    const { data, origin, originAdmin, identificacion, log_modification } = JSON.parse(localStorage.getItem('modificacion_plazo')) || dataModificacion
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorMessagePop, setErrorMessagePop] = useState(false);

    const [openConfirmacionModificacion, setOpenConfirmacionModificacion] = useState(false)
    const [msgConfirmacionModificacion, setMsgConfirmacionModificacion] = useState('')

    const [cuota, setCuota] = useState(0);
    const [valorCuota, setValorCuota] = useState(0)
    const [mincuota, setMincuota] = useState(0)
    const [maxcuota, setMaxcuota] = useState(0)
    const [paymentPlan, setPaymentPlan] = useState([]);
    const [totalPaymentPlan, setTotalPaymentPlan] = useState(0)
    const [newInterest, setNewInterest] = useState('')
    const [validCuota, setValidCuota] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //Table pagination
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 10;


    const isMobile = windowWidth < 768;

    //Confirmacion Modal
    const [openConfirmarModificacion, setOpenConfirmarModificacion] = useState(false)


    const indexOfLastItem = activePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = paymentPlan.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (!data || data.length === 0) {
            history.push(origin || '/');
        } else if (data.length > 0) {

            let cuotaMinima = data[0].plazo + 1
            if (+data[0].plazoMinimo > (+data[0].plazo + 1)) {
                cuotaMinima = data[0].plazoMinimo
            }

            setCuota(cuotaMinima);
            setMincuota(cuotaMinima);
            setMaxcuota(data[0].plazoMaximo);
        }
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChangeCuota = (event) => {
        let value = String(event.target.value).replace(/\D+/g, "");
        if (+value > maxcuota || +value < mincuota) {
            setValidCuota(false);
        } else {
            setValidCuota(true);
        }

        const numericValue = +value;
        if (numericValue > maxcuota) {
            // Limit the value to maxCuota
            setCuota(maxcuota);
            setValidCuota(true);
        } else {
            setCuota(numericValue);
        }
    };

    const handlePageChange = active_page => {
        setActivePage(active_page);
    }

    const handleBlurChangeCuota = () => {
        if (cuota > 0) {
            calcularNuevoValorCuota(cuota)
        }
    }

    const calcularNuevoValorCuota = (noCuota) => {

        let last_user = JSON.parse(Auth.getLastUserData())
        let dataSimulacion = {
            invoice: data[0].numeroObligacion,
            fiscal_number: identificacion,
            cuota: +noCuota,
            user: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',
            log_modification
        }

        setLoading(true)
        fetch(settings.backend.validarModificacionPLazo, {
            method: "POST",
            body: JSON.stringify(dataSimulacion),
            headers: settings.headers_auth(Auth.getSessionToken()),
        })
            .then(FetchResponses.processResponse)
            .then(successModificarPlazo)
            .catch(failModificarPlazo);
    }

    const successModificarPlazo = (res) => {
        setLoading(false)
        if (+res.status === 201) {
            setOpen(true)
            setErrorMessagePop(res.message)
            setValorCuota(0)
            setNewInterest(0)
            setPaymentPlan([])
        } else if (+res.status === 200) {
            setValorCuota(res.nuevaCuota.toLocaleString('de-DE', { maximumFractionDigits: 0 }))
            handleSimularCuota()
        }
    }

    const failModificarPlazo = (res) => {
        setValorCuota(0)
        setNewInterest(0)
        setPaymentPlan([])
        setLoading(false)
        setOpen(true)
        setErrorMessagePop(res.detail)
    }


    const handleSimularCuota = () => {
        let last_user = JSON.parse(Auth.getLastUserData())
        let dataSimulacion = {
            invoice: data[0].numeroObligacion,
            fiscal_number: identificacion,
            cuota,
            user: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',
        }

        setLoading(true)
        setPaymentPlan([])
        fetch(settings.backend.simularModificacionPLazo, {
            method: "POST",
            body: JSON.stringify(dataSimulacion),
            headers: settings.headers_auth(Auth.getSessionToken()),
        })
            .then(FetchResponses.processResponse)
            .then((res) => {
                let totalCuotas = res.planPagos.planPagosDetalle.reduce((acc, curr) => (acc + +curr.cuotaMes), 0)
                setLoading(false)
                setPaymentPlan(res.planPagos.planPagosDetalle)
                setNewInterest(String(res.planPagos.tasaInteres))
                setTotalPaymentPlan(totalCuotas)

            })
            .catch((err) => {
                setLoading(false)
                setOpen(true)
                setErrorMessagePop(err.detail)
            });
    }

    const handleConfirmarCambioPlan = async () => {
        const url = `${settings.backend.sendNotificationModifyTerms}${identificacion}/1`;
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: settings.headers_auth(Auth.getSessionToken()),
            });
            await FetchResponses.processResponse(response);
        } catch (error) {
            console.error("Error while confirming plan change:", error);
        } finally {
            // Ensures this function is always called
            setOpenConfirmarModificacion(true);
        }
    };


    const cerrarModalConfirmacion = (confirmarCambio = false) => {
        setOpenConfirmarModificacion(false)

        if (confirmarCambio) {
            modificarPlazoCredito()
        }
    }

    const modificarPlazoCredito = () => {
        let last_user = JSON.parse(Auth.getLastUserData())
        let dataAprobacion = {
            invoice: data[0].numeroObligacion,
            fiscal_number: identificacion,
            cuota: +cuota,
            user: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',
            log_modification,
            interest: newInterest
        }

        setLoading(true)
        fetch(settings.backend.modificarPlazoCredito, {
            method: "POST",
            body: JSON.stringify(dataAprobacion),
            headers: settings.headers_auth(Auth.getSessionToken()),
        })
            .then(FetchResponses.processResponse)
            .then((res) => {

                setLoading(false)
                setMsgConfirmacionModificacion(res.mensaje)
                setOpenConfirmacionModificacion(true)
            })
            .catch((err) => {

                setLoading(false)
                setOpen(true)
                setErrorMessagePop(err.detail)
            });
    }


    const element = () => (
        <div className="container-fluid" style={{ height: '100vh' }}>


            <div className="sidebar">
                <MenuList disabled={loading} />
            </div>
            <div className="row" style={{ minHeight: '65vh' }}>
                <div className="col private-wrapper">
                    <HeaderPrivate menu_list={loading} />
                    <div className="wrapper">
                        <StaticPageStatus>
                            <h1>
                                <span className="icon dripicons-blog" />
                                Modificación plazo crédito
                            </h1>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="panel">
                                        <label className="form-label text-uppercase">Condiciones actuales</label>
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center-important" scope="col">Fecha</th>
                                                        <th className="text-center-important" scope="col">Factura</th>
                                                        <th className="text-center-important" scope="col">Cuota</th>
                                                        <th className="text-center-important" scope="col">Saldo Capital</th>
                                                        <th className="text-center-important" scope="col">N° Cuotas pactadas</th>
                                                        <th className="text-center-important" scope="col">N° Cuotas Pagadas</th>
                                                        <th className="text-center-important" scope="col">N° Cuotas Pendientes</th>
                                                        <th className="text-center-important" scope="col">Periodicidad</th>
                                                        <th className="text-center-important" scope="col">Tasa Crédito</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(data && data.length) && data.map((invoice) => (
                                                        <tr key={`tr-invoice${invoice.numeroObligacion}`}>
                                                            <td className="text-center">{invoice.fechaObligacion}</td>
                                                            <td className="text-center">{invoice.numeroObligacion}</td>
                                                            <td className="text-center">$ {invoice.cuotaMes.toLocaleString('de-DE', { maximumFractionDigits: 0 })}</td>
                                                            <td className="text-center">$ {invoice.saldoCapital.toLocaleString('de-DE', { maximumFractionDigits: 0 })}</td>
                                                            <td className="text-center">{invoice.plazo}</td>
                                                            <td className="text-center">{invoice.cuotasPagadas}</td>
                                                            <td className="text-center">{invoice.cuotasPendientes}</td>
                                                            <td className="text-center">{invoice.periodicidadPago}</td>
                                                            <td className="text-center">{invoice.tasaInteres} %</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row justify-content-start">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="cuotas" className="form-label">MODIFICACIÓN</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon3">Ingrese Número Cuotas</span>
                                        <input
                                            type="text"
                                            max={maxcuota}
                                            min={mincuota}
                                            className={clsx("form-control", { 'is-invalid': !validCuota })}
                                            id="cuotas"
                                            value={cuota}
                                            onChange={handleChangeCuota}
                                        />
                                        <button
                                            className={clsx("btn btn-primary", { 'w-100 mt-3': isMobile })}
                                            disabled={loading || !validCuota}
                                            type="button"
                                            onClick={handleBlurChangeCuota}
                                        >
                                            Simular Plan de Pagos
                                        </button>
                                        {!validCuota ? (
                                            <div id="cuotas" className="invalid-feedback">
                                                Debe ser mayor o igual a {mincuota} e inferior o igual a {maxcuota}
                                            </div>
                                        ) : (
                                            <div >
                                                El número de cuotas a simular debe ser superior al número de cuotas pactado inicialmente ( N° cuotas pactadas)
                                            </div>
                                        )}

                                    </div>
                                </div>

                                {
                                    (valorCuota > 0) && (



                                        <div className="col-12 col-lg-3">
                                            <label htmlFor="valorQuota" className="form-label"></label>
                                            <div className="input-group mt-2">
                                                <span className="input-group-text" id="basic-addon3">Nuevo Valor Cuota</span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="valorQuota" aria-describedby="basic-addon3"
                                                    value={valorCuota}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                    )

                                        
                                }

                                {
                                    (newInterest > 0) && (
                                        <div className="col-12 col-lg-3">
                                            <label htmlFor="valorNuevaTasa" className="form-label"></label>
                                            <div className="input-group mt-2">
                                                <span className="input-group-text" id="basic-addon3">Nueva Tasa</span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="valorNuevaTasa" aria-describedby="basic-addon3"
                                                    value={ Number(newInterest).toFixed(2) + ' %'}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                            </div>

                            {
                                (loading) ?

                                    <div className="alert alert-warning">
                                        <div className="row justify-content-center">
                                            <BarLoader
                                                sizeUnit='%'
                                                height={5}
                                                width={500}
                                                color='#ff0000'
                                                loading={loading}
                                            /></div>
                                    </div> :
                                    <>
                                        {
                                            (paymentPlan && paymentPlan.length > 0) &&
                                            <div className='row d-flex justify-content-center align-items-center'>
                                                <div className='col-12 col-lg-6 col-xl-5 col-xxl-4 '>


                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-left-important" style={{ width: '30px' }} scope="col">Cuota</th>
                                                                    <th className="text-left-important" scope="col">Fecha</th>
                                                                    <th className="text-left-important" scope="col">Valor</th>
                                                                    <th className="text-left-important" scope="col">Estado</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(paymentPlan && paymentPlan.length) && currentItems.map((quota) => (
                                                                    <tr key={`tr-invoice${quota.numeroCuota}`}>
                                                                        <td>{quota.numeroCuota}</td>
                                                                        <td>{quota.fechaVencimiento}</td>
                                                                        <td>$ {quota.cuotaMes.toLocaleString('de-DE', { maximumFractionDigits: 0 })}</td>
                                                                        <td>{quota.estadoCuota}</td>
                                                                    </tr>
                                                                ))}

                                                                <tr>
                                                                    <td className='fw-bold' colspan="2">Valor Total Cuotas: </td>
                                                                    <td className='fw-bold' colspan="2">$ {totalPaymentPlan.toLocaleString('de-DE', { maximumFractionDigits: 0 })}</td>

                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <Pagination
                                                            hideDisabled
                                                            activePage={activePage}
                                                            itemsCountPerPage={itemsPerPage}
                                                            totalItemsCount={paymentPlan.length}
                                                            pageRangeDisplayed={5}
                                                            onChange={handlePageChange}
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                            innerClass={`justify-content-center pagination ${paymentPlan.length <= itemsPerPage ? 'hidden' : ''}`}
                                                        />

                                                    </div>
                                                </div>

                                                <div className='col-12 mb-3 d-flex justify-content-center align-items-center'>
                                                    <button className='btn btn-primary'
                                                        onClick={handleConfirmarCambioPlan}
                                                    >
                                                        Confirmar
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </>
                            }


                        </StaticPageStatus>
                    </div>
                </div>
            </div>
            <Footer />
            <Error
                open={!!open}
                error_message={errorMessagePop}
                handleClose={() => setOpen(false)}
            />

            <Error
                open={!!openConfirmacionModificacion}
                error_message={msgConfirmacionModificacion}
                handleClose={() => {
                    history.push(originAdmin ? '/validate_modify_terms/' : '/facturas/')

                }}
            />

            <ModalConfirmarModificacionPlazo
                openConfirmarModificacion={openConfirmarModificacion}
                cancelConfirmarModificacion={cerrarModalConfirmacion}
                fiscalNumber={identificacion}
                logModification={log_modification}
            />
        </div>
    );

    return (Auth.authenticationRequired(element()))

};

// Define mapStateToProps to access global state
const mapStateToProps = state => ({
    dataModificacion: state.modificacionPlazo.modificarPlazoInfo, // Access the specific piece of state you need
});

const mapDispatchToProps = {
    setModificarPlazoCreditoData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModificarPlazoCredito);