import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import Landing from "./PublicViews/Landing";
import Login from "./PublicViews/Login";
import Registro from "./PublicViews/Registro";
import RecuperarClave from "./PublicViews/RecuperarClave";
// import Resumen from "./PrivateViews/Summary";
import Perfil from "./PrivateViews/Perfil";
import Facturas from "./PrivateViews/Facturas";
import FacturasPago from "./PrivateViews/FacturasPago";
import Historial from "./PrivateViews/Historial";
import Puntos from "./SharedViews/Puntos";
import Documentos from "./PrivateViews/Documentos";
import DocumentoPDF from "./PrivateViews/DocumentPDF";
import CertificadoPDF from "./PrivateViews/CertificadoRenta";
import PlanDePagosPDF from "./PrivateViews/PlanDePagos";
import Logger from "../models/Logger";
import Information from "./PublicViews/Information";
import FAQ from "./PublicViews/FAQ";
import Transaction from "./PrivateViews/Transaction";
import Manager from "./AdminViews/Manager";
import ValidateRouter from "./Routers/ValidateRouter";
import Certificates from "./AdminViews/Certificates";
import AdminUser from "./AdminViews/AdminUser";
import Transactions from "./AdminViews/Transactions";
import Conciliation from "./AdminViews/Conciliation";
import AttentionLines from "./PublicViews/AttentionLines";
import CreditInformation from "./PublicViews/CreditInformation";
// import StaticPageStatus from "./SharedViews/StaticPageStatus";
// import settings from "../settings";
// import PrivateRouter from './Routers/PrivateRoute';
// import AdminRouter from './Routers/AdminRouter';
import Consult from "./PublicViews/Consult";
import FacturasPublicas from "./PublicViews/FacturasPublicas";


import Actualizacion from "./PublicViews/Actualizacion";
import Editor from "./PublicViews/Editor";
import Pages from "./AdminViews/Pages";
import Summary from "./PrivateViews/Summary";
import Sections from "./AdminViews/Sections";
import MainPage from "./PublicViews/MainPage";
import PageView from "./PublicViews/PageView";
import NotFound from "./PublicViews/NotFound";
import GenerarOtp from "./PrivateViews/GenerarOtp";
import AumentarCupo from "./PrivateViews/AumentarCupo";
import GestionarAumentoCupo from "./PrivateViews/GestionarAumentoCupo";
import ControlAdminUsers from "./AdminViews/ControlAdminUsers";
import ValidarModificacionPlazoCredito from "./AdminViews/ValidarModificacionPlazoCredito";
import ModificarPlazoCredito from "./PrivateViews/ModificarPlazoCredito";
import LogModificacionPlazo from "./AdminViews/LogModificacionPlazo";


class Router extends Component {
  static admin_pages = [
    "/manager",
    "/certificates",
    "/adminuser",
    "/pagos",
    "/conciliacion",
    "/pages",
    "/sections",
    "/controladminusers",
    "/validate_modify_terms",
    "/log_modificacion"
  ];

  static user_pages = [
    "/resumen",
    "/facturas",
    "/historial",
    "/puntos",
    "/conciliacion",
    "/documentos",
    "/perfil",
    "/generar-otp",
    "/aumentar_cupo",
    "/aumentar_cupo/gestionar",
    // "/modify_terms"
  ];

  static shared_logged_pages=[
    "/modify_terms"
  ]


  constructor(props) {
    super(props);
    Logger.setLogger(this.constructor.name);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/" component={Landing} /> */}
      
          {/*PublicViews*/}
          <Route exact path="/" component={MainPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/registro" component={Registro} />
          <Route exact path="/recuperar-clave" component={RecuperarClave} />
          <Route exact path="/informacion" component={Information} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/atencion" component={AttentionLines} />
          <Route exact path="/credito" component={CreditInformation} />
          <Route exact path="/consulta" component={Consult} />
          <Route exact path="/consulta/facturas_view" component={FacturasPublicas} />
          <Route exact path="/editor" component={Editor} />
          <Route exact path="/mainpage"  component={MainPage} />
          <Route exact path="/mainpage/:content_id"  component={PageView} />
          <Route exact path="/not-found" component={NotFound} />
          {/* Rutas adicionales */}
          <Route exact path="/actualizacion" component={Actualizacion} />
          {/*PrivateViews*/}
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact path="/perfil"
            component={Perfil}
          />

          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact path="/generar-otp"
            component={GenerarOtp}
          />

          {/* <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact path="/aumentar_cupo"
            component={AumentarCupo}
          />

          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact path="/aumentar_cupo/gestionar"
            component={GestionarAumentoCupo}
          /> */}

          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/resumen"
            component={Summary}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/facturas"
            component={Facturas}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/facturas-pago"
            component={FacturasPago}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/historial"
            component={Historial}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/documentos"
            component={Documentos}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/transaccion/:id"
            component={Transaction}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/documentoPDF"
            component={DocumentoPDF}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/certificadoPDF"
            component={CertificadoPDF}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/planDePagos"
            component={PlanDePagosPDF}
          />
          {/*Admin Views*/}
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/manager"
            component={Manager}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/controladminusers"
            component={ControlAdminUsers}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/certificates"
            component={Certificates}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/adminuser"
            component={AdminUser}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/pagos"
            component={Transactions}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/conciliacion"
            component={Conciliation}
          />
           <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/pages"
            component={Pages}
          />
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/sections"
            component={Sections}
          />

          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/validate_modify_terms"
            component={ValidarModificacionPlazoCredito}
          />

          
          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/log_modificacion"
            component={LogModificacionPlazo}
          />

          <ValidateRouter
            loggedPages={Router.shared_logged_pages}
            adminPages={Router.admin_pages}
            userPages={Router.user_pages}
            exact
            path="/modify_terms"
            component={ModificarPlazoCredito}
          />  

          {/*SharedViews*/}
          <Route exact path="/puntos" component={Puntos} />
          {/* <Route render={() => <Redirect to="/not-found"> </Redirect>} /> */}
          <Route render={() => <Redirect to="/"> </Redirect>} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
