import React, { Component } from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from "../../models/Auth";

// redux
import { connect } from 'react-redux';
import { SetMenuManager } from '../Actions/Menu_Actions';

class MenuList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      views: [], // Initial state
    };
  }


  componentDidMount() {
    // Simulate fetching the views from an external function
    const viewsFromFunction = Auth.getPermissionsModules(); // This can be an async call or an external function
    this.setState({ views: viewsFromFunction });
  }

  changueStateManager = () => {
    this.props.SetMenuManager(["submit", "", "", "", "", "", ""]);
    localStorage.setItem("menu", JSON.stringify(["submit", "", "", "", "", "", "", ""]));
  };

  changueStateCertificates = () => {
    this.props.SetMenuManager(["", "submit", "", "", "", "", "", ""]);
    localStorage.setItem("menu", JSON.stringify(["", "submit", "", "", "", "", "", ""]));
  };

  changueStateAdminUser = () => {
    this.props.SetMenuManager(["", "", "submit", "", "", "", "", ""]);
    localStorage.setItem("menu", JSON.stringify(["", "", "submit", "", "", "", "", ""]));

  };

  changueStateAdminTransactions = () => {
    this.props.SetMenuManager(["", "", "", "submit", "", "", "", ""]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "submit", "", "", "", ""]));

  };

  changueStateAdminConciliation = () => {
    this.props.SetMenuManager(["", "", "", "", "submit", "", "", ""]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "", "submit", "", "", ""]));

  };

  changeStateAdminPages = () => {
    this.props.SetMenuManager(["", "", "", "", "", "submit", "", ""]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "", "", "submit", "", ""]));
  };

  changeStateAdminSections = () => {
    this.props.SetMenuManager(["", "", "", "", "", "", "submit", ""]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "", "", "", "submit", ""]));
  };

  changueControlAdminUsers = () => {
    this.props.SetMenuManager(["", "", "", "", "", "", "", "submit"]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "", "", "", "", "submit"]));
  };

  changueControlModificacionPlazo = () => {
    this.props.SetMenuManager(["", "", "", "", "", "", "", "", "submit"]);
    localStorage.setItem("menu", JSON.stringify(["", "", "", "", "", "", "", "", "submit"]));
  };

  updateMenuAtIndex = (index) => {
    // Create an array with empty strings and set "submit" at the specified index
    const menuArray = Array(index + 1).fill("");
    menuArray[index] = "submit";
  
    // Update the state and localStorage
    this.props.SetMenuManager(menuArray);
    localStorage.setItem("menu", JSON.stringify(menuArray));
  };

  modulesElementByUser = () => (
    <>
      {/* Check if 'validate_modify_terms' exists in the array */}
      {this.state.views.includes('validate_modify_terms') && <>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[8]} onClick={this.changueControlModificacionPlazo} id="controladminusers"
            to="/validate_modify_terms">
            <span className="icon dripicons-network-1" />
            <b>Modificar Plazo Cuotas</b>
          </Link>
        </li>
      </>}

      {this.state.views.includes('log_modificacion') && <>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[9]} onClick={()=>this.updateMenuAtIndex(9)} id="controladminusers"
            to="/log_modificacion">
            <span className="icon dripicons-graph-bar" />
            <b>Log modificación plazo</b>
          </Link>
        </li>
      </>}


    </>
  );


  render() {
    return (
      <ul className="submenu">
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[0]} onClick={this.changueStateManager} id="manager" to="/manager">
            <span className="icon dripicons-toggles" />
            <b>Manager</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[1]} onClick={this.changueStateCertificates} id="certificates"
            to="/certificates">
            <span className="icon dripicons-copy" />
            <b>Certificados</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[2]} onClick={this.changueStateAdminUser} id="AdminUser" to="/adminuser">
            <span className="icon dripicons-user-group" />
            <b>Usuarios</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[3]} onClick={this.changueStateAdminTransactions} id="transactions"
            to="/pagos">
            <span className="icon dripicons-card" />
            <b>Pagos</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[4]} onClick={this.changueStateAdminConciliation} id="transactions"
            to="/conciliacion">
            <span className="icon dripicons-graph-line" />
            <b>Conciliacion</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[5]} onClick={this.changeStateAdminPages} id="pages"
            to="/pages">
            <span className="icon dripicons-copy" />
            <b>Paginas</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[6]} onClick={this.changeStateAdminSections} id="sections"
            to="/sections">
            <span className="icon dripicons-pencil" />
            <b>Secciones</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[7]} onClick={this.changueControlAdminUsers} id="controladminusers"
            to="/controladminusers">
            <span className="icon dripicons-user" />
            <b>Admin Usuarios</b>
          </Link>
        </li>

        {/* Modulos de usuarios autentificados */}
        {this.modulesElementByUser()}

      </ul>
    );
  }
}

MenuList.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  menu: state.menu.menu
})

export default connect(mapStateToProps, { SetMenuManager })(MenuList);
