import React, { Component } from "react";
import TagManager from 'react-gtm-module'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from "../../models/Auth";

// redux
import { connect } from 'react-redux';
import { SetMenuCss } from '../Actions/Menu_Actions';

class MenuList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      views: [], // Initial state
    };
  }


  componentDidMount() {
    // Simulate fetching the views from an external function
    const viewsFromFunction = Auth.getPermissionsModules(); // This can be an async call or an external function
    this.setState({ views: viewsFromFunction });
  }

  sendDataLayerInteraccionesMenuLateral = (nombre) => {
   
    let tagManagerArgs = {
      event: 'Menu-Lateral',
      category:'Menu Lateral',        
      action:'Click',
      label: nombre
    }
    TagManager.dataLayer({
      dataLayer: tagManagerArgs
    })

  }

  modulesElementByUser = () => (
    <>
      {/* Check if 'validate_modify_terms' exists in the array */}
      {this.state.views.includes('validate_modify_terms') && <>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[8]} onClick={this.changueControlModificacionPlazo} id="modificacionplazo"
            to="/validate_modify_terms">
            <span className="icon dripicons-network-1" />
            <b>Modificar Plazo Cuotas</b>
          </Link>
        </li>
      </>}

      {/* Check if 'log_modificacion' exists in the array */}
      {this.state.views.includes('log_modificacion') && <>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link className={this.props.menu[9]} onClick={this.changueControlModificacionPlazo} id="logmodificacionplazo"
            to="/log_modificacion">
            <span className="icon dripicons-graph-bar" />
            <b>Log modificación plazo</b>
          </Link>
        </li>
      </>}


    </>
  );

  render() {
    if (Auth.isAdmin()) {

      // La inicializacion y el cambio de los estados del menu en mobile se configuran en cada pagina, al hacer el componentDidMount, es diferente al menu en PC
      // Las funciones onClick de este documento no tienen una utilidad real
      return (
        <ul className="submenu">
          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[0]} onClick={this.changueStateManager} id="manager" to="/manager">
              <span className="icon dripicons-toggles" />
              <b>Manager</b>
            </Link>
          </li>
          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[1]} onClick={this.changueStateCertificates} id="certificates"
              to="/certificates">
              <span className="icon dripicons-copy" />
              <b>Certificados</b>
            </Link>
          </li>
          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[2]} onClick={this.changueStateAdminUser} id="AdminUser" to="/adminuser">
              <span className="icon dripicons-user" />
              <b>Usuarios</b>
            </Link>
          </li>
          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[3]} onClick={this.changueStateAdminTransactions} id="/pagos"
              to="/pagos">
              <span className="icon dripicons-card" />
              <b>Pagos</b>
            </Link>
          </li>
          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[4]} onClick={this.changueStateAdminConciliation} id="transactions"
              to="/conciliacion">
              <span className="icon dripicons-graph-line" />
              <b>Conciliacion</b>
            </Link>
          </li>

          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[5]} onClick={this.changeStateAdminPages} id="pages"
              to="/pages">
              <span className="icon dripicons-copy" />
              <b>Paginas</b>
            </Link>
          </li>
          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[6]} onClick={this.changeStateAdminSections} id="sections"
              to="/sections">
              <span className="icon dripicons-pencil" />
              <b>Secciones</b>
            </Link>
          </li>
          <li className={this.props.disabled ? "disabled" : "enabled"}>
            <Link className={this.props.menu[7]} onClick={this.changueControlAdminUsers} id="controladminusers"
              to="/controladminusers">
              <span className="icon dripicons-user" />
              <b>Admin Usuarios</b>
            </Link>
          </li>

          {/* Modulos de usuarios autentificados */}
          {this.modulesElementByUser()}
        </ul>
      );
    }
    return (
      <ul className="submenu">
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link onClick={ ()=>this.sendDataLayerInteraccionesMenuLateral('Resumen')} className={this.props.menu[0]} id="resumen" to="/resumen">
            <span className="icon dripicons-copy" />
            <b>Resumen</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link onClick={ ()=>this.sendDataLayerInteraccionesMenuLateral('Pagar Facturas')} className={this.props.menu[2]} to="/facturas">
            <span className="icon dripicons-article" />
            <b>Pagar Facturas</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link onClick={ ()=>this.sendDataLayerInteraccionesMenuLateral('Historial de Pagos')} className={this.props.menu[3]} to="/historial">
            <span className="icon dripicons-card" />
            <b>Historial de Pagos</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link onClick={ ()=>this.sendDataLayerInteraccionesMenuLateral('Puntos de venta')} className={this.props.menu[4]} to="/puntos">
            <span className="icon dripicons-location" />
            <b>Puntos de venta</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link onClick={ ()=>this.sendDataLayerInteraccionesMenuLateral('Documentos certificados')} className={this.props.menu[5]} to="/documentos">
            <span className="icon dripicons-blog" />
            <b>Documentos certificados</b>
          </Link>
        </li>
        <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link onClick={ ()=>this.sendDataLayerInteraccionesMenuLateral('Perfil')} className={this.props.menu[1]} to="/perfil">
            <span className="icon dripicons-user" />
            <b>Perfil</b>
          </Link>
        </li>

        {/* Modulos de usuarios autentificados */}
        {this.modulesElementByUser()}

        {/* <li className={this.props.disabled ? "disabled" : "enabled"}>
          <Link onClick={ ()=>this.sendDataLayerInteraccionesMenuLateral('Aumentar cupo')} className={this.props.menu[6]} to="/aumentar_cupo">
            <span className="icon dripicons-arrow-up" />
            <b>Aumentar cupo</b>
          </Link>
        </li> */}       
      </ul>
    );
  }
}

MenuList.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  menu: state.menu.menu
})

export default connect(mapStateToProps, { SetMenuCss })(MenuList);
